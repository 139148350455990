import Arrow from '@/components/icons/Arrow';
import ProductCard from '@/components/cards/ProductCard';

import { useEffect, useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSWR, { useSWRConfig } from 'swr';
import { getCookie } from 'cookies-next';
import { getProductsByCategoryId } from '@/services/category';
import Link from 'next/link';
import { handleLinkByType } from '@/helpers/common/handleLinkByType';
import LazyLoading from '@/components/LazyLoading';
import useScreenSize from '@/hooks/display/useDisplay';
import { event_view_item_list } from '@/utils/gtm';
import UseLocale from '@/hooks/useLocale';
import NextImage from '../NextImage';
import { validateImgSrc } from '@/utils/global';

const OurSwiper = ({ isMobile, data, updateStyle = false }) => {
  const [swiper, set_swiper] = useState({});
  const [active, setActive] = useState(false);
  const { screenWidth } = useScreenSize();
  const { locale } = UseLocale();

  const { cache } = useSWRConfig();
  const cacheKey = `home-banner-with-products/${data?.value?.[0]?.id}/${locale}`;
  const handshake = getCookie('handshake');
  const { data: productsData, isValidating } = useSWR(
    cacheKey,
    () =>
      getProductsByCategoryId(
        handshake,
        data?.value?.[0]?.id,
        '',
        15,
        0,
        false
      ),
    {
      revalidateOnFocus: false,
      revalidateOnMount: !cache.get(cacheKey),
      onSuccess: (res) => {
        const products = res?.data?.data?.products;
        const currentCategory = 'banner products list';
        const categoryID = 'banner products list';
        event_view_item_list(
          currentCategory,
          categoryID,
          products,
          getCookie('couponCode') || null
        );
      },
    }
  );

  const products = useMemo(
    () =>
      productsData?.data?.data?.products?.filter(
        (product) => product?.stock?.is_in_stock
      ) || [],
    [productsData]
  );

  useEffect(() => {
    // const products = res?.data?.data?.products;
    const currentCategory = data?.value?.[0]?.[`title-${locale}`];
    const categoryID = data?.value?.[0]?.id;
    setTimeout(() => {
      (updateStyle ? productsData?.data?.data?.products : products)?.length &&
        event_view_item_list(
          currentCategory,
          categoryID,
          updateStyle ? productsData?.data?.data?.products : products,
          getCookie('couponCode') || null
        );
    }, 500);
  }, [
    data?.value,
    locale,
    products,
    productsData?.data?.data?.products,
    updateStyle,
  ]);

  const bgImg = isMobile
    ? data?.[`mobile-img-${locale}`] || false
    : data?.[`img-${locale}`] || false;

  return data?.active && data?.value?.length > 0 ? (
    <>
      {isValidating ? (
        <LazyLoading parentClassName={'min-h-[383px] sm:min-h-[450px]'} />
      ) : (updateStyle ? productsData?.data?.data?.products : products)
          ?.length ? (
        <>
          <div className='relative my-4 flex items-center p-1 py-4 max-sm:ps-3 sm:p-4 '>
            <div
              className={`absolute start-0 -z-20 size-full bg-black ${!updateStyle ? 'sm:w-[400px]' : '570px:bg-cover bg-opacity-50 bg-contain'}`}
              style={
                !updateStyle
                  ? { backgroundColor: data['bg-color'] }
                  : bgImg
                    ? { backgroundImage: `url(${bgImg})` }
                    : {}
              }
            ></div>
            {active ? (
              <button
                onClick={() => swiper?.slidePrev()}
                className='absolute -start-2 top-1/2 z-50 grid size-8 -translate-y-1/2 place-items-center rounded-full border bg-white shadow-xl max-sm:hidden'
              >
                <Arrow
                  className={locale === 'en' ? 'rotate-90' : '-rotate-90'}
                />
              </button>
            ) : null}
            <button
              onClick={() => swiper?.slideNext()}
              className='absolute -end-2 top-1/2 z-50 grid size-8 -translate-y-1/2 place-items-center rounded-full border bg-white shadow-xl max-sm:hidden'
            >
              <Arrow className={locale === 'en' ? '-rotate-90' : 'rotate-90'} />
            </button>

            <div className='w-full overflow-hidden'>
              <Swiper
                onInit={(e) => set_swiper(e)}
                key={locale}
                onSlideChange={(info) =>
                  info?.activeIndex === 0 ? setActive(false) : setActive(true)
                }
                dir={locale === 'en' ? 'ltr' : 'rtl'}
                className={`h-full sm:ps-2 ${!isMobile ? 'w-[1440px]' : `w-` + screenWidth + 'px'}`}
                slidesPerView={isMobile ? 2.5 : 6}
                spaceBetween={isMobile ? 7 : 16}
                // breakpoints={{
                //   320: { slidesPerView: 2.5, spaceBetween: 16 },
                //   641: { slidesPerView: 3.5, spaceBetween: 16 },
                //   1024: { slidesPerView: 6, spaceBetween: 16 },
                // }}
              >
                {!updateStyle && (
                  <SwiperSlide>
                    <div className='flex h-[268px] flex-col justify-center sm:h-[402px]'>
                      <Link
                        href={handleLinkByType(
                          data?.value?.[0]?.url,
                          data?.value?.[0]?.type
                        )}
                        className='block size-full '
                      >
                        <NextImage
                          
                          src={validateImgSrc(
                            !isMobile
                              ? data?.value?.[0]?.[`img-${locale}`]
                              : data?.value?.[0]?.[`mobile-img-${locale}`]
                          )}
                          height={200}
                          width={220}
                          className='h-full object-cover'
                          alt='Or Swiper Img!'
                        />
                      </Link>
                    </div>
                  </SwiperSlide>
                )}
                {(updateStyle
                  ? productsData?.data?.data?.products
                  : products
                )?.map((product, i) => (
                  <SwiperSlide
                    className='bg-white p-1 *:w-full sm:p-3'
                    key={`product-swiper-1-${product.sku}`}
                  >
                    <ProductCard
                      product={product}
                      categoryID={data?.value?.[0]?.id || null}
                      categoryName={
                        data?.value?.[0]?.[`title-${locale}`] || null
                      }
                      fromBannerProductsList
                      loading={isMobile ? i <= 2 ? 'eager' : 'lazy' : i > 7 ? 'eager' : 'lazy'}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </>
      ) : null}
    </>
  ) : null;
};

export default OurSwiper;
